.button_wrapper {
    min-width: max-content;
    width: 100%;
    text-decoration: none !important;

    .navbar_button {
        display: flex;
        padding: 9px 20px 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        border: 2px solid rgba(255, 255, 255, 0.20);
        background-color: transparent;
        cursor: pointer;
        transition-duration: 0.5s;
    }

    .normal_button {
        display: flex;
        width: 100%;
        height: 52px;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        border: 1px solid #030A21;
        cursor: pointer;
        transition-duration: 0.5s;
    }

    .homepage_button {
        display: flex;
        width: 100%;
        height: 52px;
        padding: 20px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 12px;
        border: 1px solid #002726;
        cursor: pointer;
        transition-duration: 0.5s;
        background-color: white;

        p{
            color: #002726 !important;
        }
    }

    .article_button{
        width: --webkit-fill-available;
        display: flex;
        align-items: center;
        gap: 6px;
        height: 58px;
        padding: 16px 20px;
        border: 1px solid #C6C8CD;
        border-radius: 12px;
        background-color: white;
        transition-duration: 0.5s;
    }
    .article_button:hover {
        background-color: rgba(3, 10, 33, 0.05);
    }

    .normal_button:disabled {
        background-color: rgba(3, 10, 33, 0.20) !important;
        border: none !important;
    }
    .homepage_button:hover {
        background-color: #002726;
        p{
            color: white !important;
        }
    }
    .disabled_button {
        cursor: unset !important;
    }
}

@media screen and (max-width:800px) {
    .button_wrapper {
        .navbar_button {
            border-radius: 4px !important;
        }}
}